import {
  Component
} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'showroom';
  productList = [{
      imageSrc: 'assets/1000-696.jpg',
      picHeight: 69.6,
      picWidth: 100,
      price: 1500,
      name: "pic1",
      artist: 'Munch'
    },
    {
      imageSrc: 'assets/470-599.jpg',
      picHeight: 59.9,
      picWidth: 47,
      price: 2500,
      name: "scream",
      artist: "Munch"
    },
    {
      imageSrc: 'assets/950-576.jpg',
      picHeight: 95,
      picWidth: 57.6,
      price: 3000,
      name: 'picture3',
      artist: 'Munch'
    }
  ]
}
