import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import AFRAME from 'aframe';

let buttonArrowRadius = 0.1
let prevButton = {
  pos: '-0.3 0 0',
  img: 'assets/previous.png',
  radius: buttonArrowRadius,
  componentName: 'prev'
}
let nextButton = {
  pos: '0.3 0 0',
  img: 'assets/next.png',
  radius: buttonArrowRadius,
  componentName: 'next'
}

AFRAME.registerComponent('spot',{
  events:{
    click: (e) => {
      let camera:any = document.querySelector('[camera_look_at]')
      let target:any = e.target
        target.object3D.getWorldPosition(camera.object3D.position).add(new AFRAME.THREE.Vector3(0, 1.5, 0))
    let a = new AFRAME.THREE.Spherical()
    let b = new AFRAME.THREE.Vector3()
    e.target.parentEl.querySelector('.picture').object3D.getWorldPosition(b)
    b.sub(camera.object3D.position)
    a.setFromVector3(b)
    camera.components['look-controls'].pitchObject.rotation.x = Math.PI/2 - a.phi
    camera.components['look-controls'].yawObject.rotation.y = a.theta + Math.PI
      
    }
  }
})

AFRAME.registerComponent('imgspot', {
  schema: {
    iterator: {
      type: 'int',
      default: 0
    },
    images: {
      type: 'array',
      default: []
    }
  },

  update: function () {
    if (isNaN(this.data.iterator)) this.data.iterator = 0
    if (this.data.iterator > this.data.images.length - 1) this.data.iterator = 0
    if (this.data.iterator < 0) this.data.iterator += this.data.images.length
    this.updateCurrImg()
  },
  updateCurrImg() {
    if (!this.data.images) {
      return
    }
    let currImg = this.data.images[this.data.iterator]
    let infoBox = this.el.childNodes[1]
    infoBox.setAttribute('text', 
    `align: center; 
    wrapCount: 20;
    value: Price ${currImg.price}
           Autor: ${currImg.artist}
           Name: ${currImg.name}`)
    let picture = this.el.childNodes[0]
    picture.setAttribute('src', currImg.imageSrc)
    picture.setAttribute('height', currImg.picHeight / 100)
    picture.setAttribute('width', currImg.picWidth / 100)
  }
})
AFRAME.registerComponent('next', {
  events: {
    click: (e) => {
      let component = e.target.parentEl.parentEl
      component.setAttribute('imgspot', {
        iterator: component.components.imgspot.data.iterator + 1
      })
    }
  }
})
AFRAME.registerComponent('prev', {
  events: {
    click: (e) => {
      let component = e.target.parentEl.parentEl
      console.log(component)
      component.setAttribute('imgspot', {
        iterator: component.components.imgspot.data.iterator - 1
      })
    }
  }
})


@Component({
  selector: 'app-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.css']
})



export class ShowroomComponent implements OnInit {

  @Input()
  products;
  title = 'showroom';
  image;
  AFRAME;

  artObj = {
    createMainEntity(coords) {
      let picEntity = document.createElement('a-entity')
      picEntity.appendChild(this.createPicPlane())
      picEntity.appendChild(this.createInfoBox())
      picEntity.appendChild(this.createButtonsWrap())
      picEntity.appendChild(this.createSpot(coords))
      return picEntity
    },

    createPicPlane() {
      let picPlane = document.createElement('a-plane')
      picPlane.setAttribute('class','picture')
      return picPlane
    },

    createInfoBox() {
      let info = document.createElement('a-plane')
      info.setAttribute('material', 'color: gray')
      info.setAttribute('position', '1 0 0')
      info.setAttribute('id', 'info')
      info.setAttribute('geometry',' width: 1; height: auto')
      return info
    },

    createButtonsWrap() {
      let wrap = document.createElement('a-entity')
      wrap.appendChild(this.createButton(prevButton))
      wrap.appendChild(this.createButton(nextButton))
      wrap.setAttribute('position', '0 -0.6 0')
      return wrap
    },

    createButton(options) {
      let button = document.createElement('a-circle')
      button.setAttribute(options.componentName, '')
      button.setAttribute('geometry', `radius: ${options.radius}`)
      button.setAttribute('src', options.img)
      button.setAttribute('position', options.pos)
      button.setAttribute('class', 'raycasted')
      return button
    }, 

    createSpot(coords){
      let spot = document.createElement('a-circle')
      spot.setAttribute('radius', '0.3')
      spot.setAttribute('position', coords)
      spot.setAttribute('spot', '')
      spot.setAttribute('rotation', '-90 0 0')
      spot.setAttribute('class', 'spot raycasted')
      return spot
    },

    createManualSpot(coords, look){
      let mainEntity = document.createElement('a-entity')
      let entity = document.createElement('a-entity')
      entity.setAttribute('class','picture')
      entity.setAttribute('position', look)
      mainEntity.appendChild(entity)
      mainEntity.appendChild(this.createSpot(coords))
      return mainEntity
    }
  }

  ngOnInit() {
    let pos = '0 -1.5 1'
    let scene = document.querySelector('a-scene')

    let first: any = this.artObj.createMainEntity(pos)
    first.setAttribute('position', '-3 1.8 1')
    first.setAttribute('rotation', '0 90 0')
    first.setAttribute('imgspot', {
      iterator: 0,
      images: this.products
    })
    scene.appendChild(first)

    let second: any = this.artObj.createMainEntity(pos)
    second.setAttribute('position', '3.2 1.8 -3.85')
    second.setAttribute('rotation', '0 -90 0')
    second.setAttribute('imgspot', {
      iterator: 1,
      images: this.products
    })
    scene.appendChild(second)

    let third: any = this.artObj.createMainEntity(pos)
    third.setAttribute('position', '0 1.8 3.23')
    third.setAttribute('rotation', '0 180 0')
    third.setAttribute('imgspot', {
      iterator: 2,
      images: this.products
    })
    scene.appendChild(third)

    let cursorVR = document.querySelector('#cursorVR')
    let cursorPC = document.querySelector('#cursorPC')

    scene.addEventListener('enter-vr', ()=> {
      cursorVR.setAttribute('visible', 'true')
      cursorVR.setAttribute('raycaster', 'enabled: true;')
      cursorPC.setAttribute('raycaster', 'enabled: false')
    });

    scene.addEventListener('exit-vr', ()=> {
      
      cursorVR.setAttribute('visible', 'false')
      cursorPC.setAttribute('raycaster', 'enabled: true;')
    });

    document.querySelector('a-scene').addEventListener('loaded', function () {
      this.camera.el.setAttribute('camera', 'active: false')
    })
    scene.appendChild(this.artObj.createManualSpot('-1.5 0.3 -7.8', '0 1.8 0'))
    scene.appendChild(this.artObj.createManualSpot('0.77 0.3 0', '-1.75 1.8 1.75'))
  }
}
